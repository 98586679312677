<template>
  <div class="meeting-loader">
    <div class="meeting-loader-content">
      <img
        src="@/assets/icons/ai.svg"
        alt=""
        class="meeting-loader-content-icon"
      />
      <p class="meeting-loader-content-text">
        {{ stages[currentStage] }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingContentLoading',
  props: {
    type: {
      type: String,
      default: 'challenges',
      validator: (value) => {
        return [
          'challenges',
          'inspirations',
          'offerings',
          'references',
          'audience',
          ''
        ].includes(value)
      }
    },
    customStages: {
      type: Array,
      default: () => []
    },
    timer: {
      type: Number,
      default: 5000
    }
  },
  data: () => ({
    currentStage: 0,
    interval: null
  }),
  computed: {
    stages() {
      if (this.customStages?.length) {
        return this.customStages
      }
      const stagesMap = {
        audience: [
          'Finding people of interest',
          'Identifying decision makers',
          'Analysing company structure'
        ],
        challenges: [
          'Analysing client website',
          'Analysing company & industry news',
          'Extracting challenges for your audience'
        ],
        inspirations: [
          'Loading your inspirational content - blogs, white papers, webinars, ..',
          'Matching relevant inspirational content'
        ],
        offerings: [
          'Loading your offerings',
          'Matching relevant offerings with identified challenges'
        ],
        references: ['Loading your cases', 'Matching relevant cases']
      }
      return stagesMap[this.type]
    }
  },
  mounted() {
    this.startRotation()
  },
  beforeDestroy() {
    this.stopRotation()
  },
  methods: {
    startRotation() {
      this.interval = setInterval(() => {
        this.rotateStage()
      }, this.timer)
    },
    stopRotation() {
      clearInterval(this.interval)
    },
    rotateStage() {
      this.currentStage = (this.currentStage + 1) % this.stages.length
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-loader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid rgba(#000, 8%);

  &-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;

    &-icon {
      filter: brightness(0) saturate(100%) invert(41%) sepia(14%) saturate(198%)
        hue-rotate(165deg) brightness(91%) contrast(93%);
      animation: spin 1s linear infinite;
    }

    &-text {
      color: #60666b;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
