var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "meeting-loader" }, [
    _c("div", { staticClass: "meeting-loader-content" }, [
      _c("img", {
        staticClass: "meeting-loader-content-icon",
        attrs: { src: require("@/assets/icons/ai.svg"), alt: "" }
      }),
      _c("p", { staticClass: "meeting-loader-content-text" }, [
        _vm._v(" " + _vm._s(_vm.stages[_vm.currentStage]) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }