var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "template",
      class: {
        selectable: _vm.selectable,
        selected: _vm.selected,
        disabled: _vm.disabled
      },
      on: { click: _vm.handleSelect }
    },
    [
      _c("div", { staticClass: "template-info" }, [
        _c("div", { staticClass: "template-info-title" }, [
          _c("img", {
            staticClass: "template-info-title-icon",
            attrs: {
              src: require("@/assets/file_type/" +
                (_vm.isPresentation ? "powerpoint" : "word") +
                ".svg"),
              alt: ""
            }
          }),
          _c(
            "div",
            {
              staticClass: "template-info-title-name",
              attrs: { title: _vm.template.name }
            },
            [_vm._v(" " + _vm._s(_vm.template.name) + " ")]
          ),
          _c(
            "div",
            { staticClass: "template-info-title-action" },
            [
              _vm._t("action", function() {
                return [
                  _vm.selectable
                    ? _c("Checkbox", {
                        staticClass: "template-info-title-action-checkbox",
                        attrs: { value: _vm.selected }
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ]),
        _vm.template.description
          ? _c("p", { staticClass: "template-info-description" }, [
              _vm._v(" " + _vm._s(_vm.template.description) + " ")
            ])
          : _vm._e(),
        _vm.showStatus || _vm.showSource
          ? _c(
              "div",
              { staticClass: "template-info-tags" },
              [
                _vm.showStatus
                  ? _c("Tag", {
                      attrs: {
                        text: _vm.template.status.capitalize(),
                        type: "white",
                        size: "xxs"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "left",
                            fn: function() {
                              return [
                                _c("StatusOrb", {
                                  attrs: {
                                    type: ["published", "visible"].includes(
                                      _vm.template.status
                                    )
                                      ? "green"
                                      : "grey",
                                    size: "xxs"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        456653165
                      )
                    })
                  : _vm._e(),
                _vm.showSource
                  ? _c("Tag", {
                      attrs: {
                        text: _vm.source.name,
                        type: "white",
                        size: "xxs"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "left",
                            fn: function() {
                              return [
                                _vm.source.image
                                  ? _c("Avatar", {
                                      attrs: {
                                        user: { avatar: _vm.source.image },
                                        size: "xxs"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1998268557
                      )
                    })
                  : _vm._e(),
                _c(
                  "b-tooltip",
                  { attrs: { label: "Cannot be edited", type: "is-dark" } },
                  [
                    _vm.showStatic && !_vm.template.dynamic
                      ? _c("Tag", {
                          attrs: {
                            text: "Fixed",
                            icon: "lock-filled",
                            "icon-left": true,
                            type: "white",
                            size: "xxs"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }