<template>
  <div
    class="template"
    :class="{ selectable, selected, disabled }"
    @click="handleSelect"
  >
    <div class="template-info">
      <div class="template-info-title">
        <img
          :src="
            require(`@/assets/file_type/${
              isPresentation ? 'powerpoint' : 'word'
            }.svg`)
          "
          alt=""
          class="template-info-title-icon"
        />
        <div class="template-info-title-name" :title="template.name">
          {{ template.name }}
        </div>
        <div class="template-info-title-action">
          <slot name="action">
            <Checkbox
              v-if="selectable"
              :value="selected"
              class="template-info-title-action-checkbox"
            />
          </slot>
        </div>
      </div>
      <p v-if="template.description" class="template-info-description">
        {{ template.description }}
      </p>
      <div v-if="showStatus || showSource" class="template-info-tags">
        <Tag
          v-if="showStatus"
          :text="template.status.capitalize()"
          type="white"
          size="xxs"
        >
          <template #left>
            <StatusOrb
              :type="
                ['published', 'visible'].includes(template.status)
                  ? 'green'
                  : 'grey'
              "
              size="xxs"
            />
          </template>
        </Tag>
        <Tag v-if="showSource" :text="source.name" type="white" size="xxs">
          <template #left>
            <Avatar
              v-if="source.image"
              :user="{ avatar: source.image }"
              size="xxs"
            />
          </template>
        </Tag>
        <b-tooltip label="Cannot be edited" type="is-dark">
          <Tag
            v-if="showStatic && !template.dynamic"
            text="Fixed"
            icon="lock-filled"
            :icon-left="true"
            type="white"
            size="xxs"
          />
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '@c/library/Tag.vue'
import StatusOrb from '@c/library/StatusOrb.vue'
import Checkbox from '@c/library/Checkbox.vue'
import Avatar from '@c/library/Avatar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TemplateCard',
  components: { Tag, StatusOrb, Checkbox, Avatar },
  props: {
    template: {
      type: Object,
      default: () => ({})
    },
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    showSource: {
      type: Boolean,
      default: true
    },
    showStatic: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'currentUser']),
    isPresentation() {
      return (
        this.template.type === 'presentation' ||
        this.template.file_type === 'pptx'
      )
    },
    source() {
      return {
        custom: {
          name: this.currentWorkspace.name,
          image: this.currentWorkspace.image || ''
        },
        core: {
          name: 'uman.ai',
          image: require('@/assets/logo.svg')
        },
        user: {
          name: 'My templates',
          image: this.currentUser.avatar || ''
        }
      }[this.template.provider || 'custom']
    }
  },
  methods: {
    handleSelect() {
      if (this.selectable && !this.disabled) this.$emit('select', this.template)
    }
  }
}
</script>

<style lang="scss" scoped>
.template {
  border-radius: 12px;
  border: 2px solid rgba(#000, 0.08);

  &.selectable {
    cursor: pointer;
  }

  &.selected {
    border: 2px solid $primary;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }

  &-info {
    padding: 1.25rem;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.5rem;

    &-title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &-icon {
        height: 1.5rem;
      }

      &-name {
        font-size: 1.25rem;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-action {
        margin-left: auto;

        &-checkbox {
          pointer-events: none;
        }
      }
    }

    &-description {
      color: #8f9399;
    }

    &-tags {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      padding-top: 0.5rem;
    }
  }
}
</style>
