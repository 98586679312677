import { Paginator, getClient, getResponseData } from '../../../store/src/axios'

const workspaceClient = getClient('/workspaces')

export async function getMeetings({ workspace_id }) {
  const res = await workspaceClient.get(`/${workspace_id}/stories/`, {
    params: { ordering: '-date_updated' }
  })
  return getResponseData(res)
}

export async function getMeetingsPaginator({ workspace_id, extended }) {
  return new Paginator(
    workspaceClient,
    await workspaceClient.get(`/${workspace_id}/stories/`, {
      params: { ordering: '-date_updated', extended }
    })
  )
}

export async function getMeeting({ workspace_id, meeting_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${meeting_id}/`
  )
  return getResponseData(res)
}

export async function removeMeeting({ workspace_id, meeting_id }) {
  const res = await workspaceClient.delete(
    `/${workspace_id}/stories/${meeting_id}/`
  )
  return getResponseData(res)
}

export async function createMeeting({
  workspace_id,
  account_id,
  people,
  note_ids,
  model
}) {
  const res = await workspaceClient.post(`/${workspace_id}/stories/`, {
    account_id,
    people,
    ...(note_ids && { note_ids }),
    ...(model && { model })
  })
  return getResponseData(res)
}

export async function editMeeting({
  workspace_id,
  story_id,
  title,
  people,
  note_ids,
  offering_ids,
  reference_ids,
  inspiration_ids
}) {
  const res = await workspaceClient.patch(
    `/${workspace_id}/stories/${story_id}/`,
    {
      ...(title ? { title } : {}),
      ...(people ? { people } : {}),
      ...(note_ids ? { note_ids } : {}),
      ...(offering_ids ? { offering_ids } : {}),
      ...(reference_ids ? { reference_ids } : {}),
      ...(inspiration_ids ? { inspiration_ids } : {})
    }
  )
  return getResponseData(res)
}

export async function autocompleteMeeting({ workspace_id, story_id }) {
  const res = await workspaceClient.post(
    `/${workspace_id}/stories/${story_id}/autocomplete/`
  )
  return getResponseData(res)
}

export async function getAutocompleteInsights({ workspace_id, story_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/insights/`
  )
  return getResponseData(res)
}

export async function getMeetingAccount({ workspace_id, story_id, fields }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/account/`,
    {
      params: { fields: fields?.length ? fields?.join(',') : null }
    }
  )
  return getResponseData(res)
}

export async function getMeetingChallenges({ workspace_id, story_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/challenges/`
  )
  return getResponseData(res)
}

export async function getMeetingOfferings({
  workspace_id,
  story_id,
  exclude_root_ids = null,
  exclude_ids = null,
  root_id = null
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/offerings/`,
    { params: { exclude_root_ids, exclude_ids, root_id } }
  )
  return res.data
}

export async function getMeetingOfferingInfo({
  workspace_id,
  story_id,
  ori_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/offerings/${ori_id}/`
  )
  return getResponseData(res)
}

export async function getMeetingSubOfferings({
  workspace_id,
  story_id,
  ori_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/offerings/${ori_id}/subofferings/`
  )
  return getResponseData(res)
}

export async function getMeetingReferences({ workspace_id, story_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/references/`
  )
  return getResponseData(res)
}

export async function getMeetingReferenceInfo({
  workspace_id,
  story_id,
  ori_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/references/${ori_id}/`
  )
  return getResponseData(res)
}

export async function getMeetingInspirations({ workspace_id, story_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/inspirations/`
  )
  return getResponseData(res)
}

export async function getMeetingInspirationInfo({
  workspace_id,
  story_id,
  ori_id
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/inspirations/${ori_id}/`
  )
  return getResponseData(res)
}

export async function getMeetingOutputs({
  workspace_id,
  story_id,
  deleted = undefined
}) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/outputs/`,
    { params: { ...(deleted !== undefined ? { deleted } : {}) } }
  )
  return getResponseData(res)
}

export async function getMeetingOutput({ workspace_id, story_id, output_id }) {
  const res = await workspaceClient.get(
    `/${workspace_id}/stories/${story_id}/outputs/${output_id}/`
  )
  return getResponseData(res)
}

export async function createMeetingOutput({
  workspace_id,
  story_id,
  tool_id,
  settings,
  test = undefined
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/stories/${story_id}/outputs/`,
    {
      tool_id,
      ...(test !== undefined ? { test } : {})
    },
    { params: { ...(settings || {}) } }
  )
  return getResponseData(res)
}

export async function editMeetingOutputName({
  workspace_id,
  story_id,
  output_id,
  name
}) {
  const res = await workspaceClient.patch(
    `/${workspace_id}/stories/${story_id}/outputs/${output_id}/`,
    { name }
  )
  return getResponseData(res)
}

export async function editMeetingOutput({
  workspace_id,
  story_id,
  output_id,
  slide_index,
  settings,
  prompt = undefined
}) {
  const res = await workspaceClient.put(
    `/${workspace_id}/stories/${story_id}/outputs/${output_id}/`,
    {
      ...(prompt !== undefined ? { prompt } : {}),
      ...(slide_index > -1 && { slide_index })
    },
    { params: { ...(settings || {}) } }
  )
  return getResponseData(res)
}

export async function addMeetingOutputSlides({
  workspace_id,
  story_id,
  output_id,
  slide_index,
  subresource_ids
}) {
  const res = await workspaceClient.post(
    `/${workspace_id}/stories/${story_id}/outputs/${output_id}/slides/`,
    [
      {
        slide_index,
        subresource_ids
      }
    ]
  )
  return getResponseData(res)
}

export async function removeMeetingOutputSlide({
  workspace_id,
  story_id,
  output_id,
  slide_index
}) {
  const res = await workspaceClient.delete(
    `/${workspace_id}/stories/${story_id}/outputs/${output_id}/slides/`,
    { params: { slide_index } }
  )
  return getResponseData(res)
}

export async function removeMeetingOutput({
  workspace_id,
  story_id,
  output_id
}) {
  const res = await workspaceClient.delete(
    `/${workspace_id}/stories/${story_id}/outputs/${output_id}/`
  )
  return getResponseData(res)
}
