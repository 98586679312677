<template>
  <div class="meeting-error">
    <div class="meeting-error-content">
      <img
        src="@/assets/icons/check-warning.svg"
        alt=""
        class="meeting-error-content-icon"
      />
      <p class="meeting-error-content-text">
        {{ message }}
      </p>
    </div>
    <Button text="Retry" type="white" @click="$emit('retry')" />
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'

export default {
  name: 'MeetingContentError',
  components: { Button },
  props: {
    type: {
      type: String,
      default: 'challenges',
      validator: (value) => {
        return [
          'challenges',
          'inspirations',
          'offerings',
          'references',
          'audience',
          ''
        ].includes(value)
      }
    },
    customMessage: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    message() {
      if (this.customMessage) {
        return this.customMessage
      }
      const subjectMap = {
        challenges: 'challenge',
        inspirations: 'inspirational content',
        offerings: 'offering',
        references: 'reference',
        audience: 'audience'
      }
      return `Something went wrong while generating ${
        subjectMap[this.type]
      } suggestions.`
    }
  }
}
</script>

<style scoped lang="scss">
.meeting-error {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid rgba(#000, 8%);
  background: white;

  &-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;

    &-icon {
      filter: brightness(0) saturate(100%) invert(41%) sepia(14%) saturate(198%)
        hue-rotate(165deg) brightness(91%) contrast(93%);
      height: 1.2rem;
    }

    &-text {
      color: #60666b;
      margin-right: auto;
    }
  }
}
</style>
