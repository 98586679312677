var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-error" },
    [
      _c("div", { staticClass: "meeting-error-content" }, [
        _c("img", {
          staticClass: "meeting-error-content-icon",
          attrs: { src: require("@/assets/icons/check-warning.svg"), alt: "" }
        }),
        _c("p", { staticClass: "meeting-error-content-text" }, [
          _vm._v(" " + _vm._s(_vm.message) + " ")
        ])
      ]),
      _c("Button", {
        attrs: { text: "Retry", type: "white" },
        on: {
          click: function($event) {
            return _vm.$emit("retry")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }